<template>
    <div class="home">
        <v-row class="first">
            <v-spacer></v-spacer>
            <v-col sm="12" lg="8" align-self="center">
                <template>
                    <v-card class="mx-auto pb-4 px-4 av-card" elevation="24" outlined>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <div class="text-caption text-xl-overline mb-2">Put the brakes on your code!</div>
                                <v-list-item-title
                                    v-if="this.$vuetify.breakpoint.name == 'xs'"
                                    class="title text-uppercase font-weight-thin"
                                >Slowdown to Speedup</v-list-item-title>
                                <v-list-item-title v-else class="display-2 font-weight-thin mb-2">
                                    <span style="font-stretch: ultra-condensed">Slowdown</span> to Speed Up
                                </v-list-item-title>
                                <v-list-item-subtitle></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-card-text class="text-subtitle-2 text-xl-h5 font-weight-light">
                            That may sound contradictory... yet it's true. While debugging, putting the
                            <span
                                class="white--text black text--darken-1"
                            >brake</span>s on your
                            <span class="yellow--text black text--darken-1">code</span> is exactly what IS needed. Slow down your code AND speed up your workflow!
                        </v-card-text>
                        <v-card-actions>
                            <v-btn outlined :large="!$vuetify.breakpoint.mobile"
                                :x-small="$vuetify.breakpoint.mobile" @click="signup()" text>Get Started</v-btn>
                            <v-btn outlined
                                :large="!$vuetify.breakpoint.mobile"
                                :x-small="$vuetify.breakpoint.mobile"
                                href="https://blog.brakecode.com/brakecode"
                                text
                            >Learn More</v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-col>
            <v-col lg="3"></v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="second py-0 py-xl-8">
            <v-img class="row2Background" max-height="300px" :src="items[selected] ? items[selected].src : ''"></v-img>
            <v-spacer></v-spacer>
            <v-col sm="12" xl="5">
                <template>
                    <v-carousel vertical transition="fade-transition" :show-arrows="false" hide-delimiters cycle v-model="selected">
                        <v-carousel-item contain
                            v-for="(item,i) in items"
                            :key="i"
                            :src="item.src"
                            :href="item.url"
                            :ripple="{ class: 'yellow--text' }"
                        ></v-carousel-item>
                    </v-carousel>
                </template>
            </v-col>
            <v-col sm="12" xl="4" class="d-flex flex-column justify-center" style="flex-basis: unset;">
                <v-list rounded three-line>
                    <template v-for="(item, index) in items">
                        <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>
                        <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>
                        <v-list-item v-else :key="item.title" :input-value="selected === index" v-bind:class="{ shrink: !active }" active-class="yellow lighten-1" @click='expand(index)'>
                            <template v-slot:default="{ active }">
                                <v-list-item-content>
                                    <v-list-item-title class="" v-bind:class="{ 'text-subtitle-2 text-xl-h5 text-uppercase': active }" v-html="item.title"></v-list-item-title>
                                    <v-list-item-subtitle
                                        class="font-weight-light text-caption text-xl-subtitle-1"
                                        v-if="active"
                                        v-html="item.subtitle"
                                    >
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-4" v-if="active"><a :href="item.url">Learn About It</a></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </div>
</template>
<style scoped>
.v-list--rounded.v-list--three-line .v-list-item, .v-list--rounded.v-list--three-line .v-list-item::before, .v-list--rounded.v-list--three-line .v-list-item > .v-ripple__container {
    border-radius: 10px !important;
}
.row2Background {
    opacity: 0.1;
    position: absolute;
}
.shrink {
    min-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top:0;
    padding-bottom:0;
}
a {
    text-decoration: none;
}
.home .first {
    background-image: url(/blog-1-asphalt-cement-concrete.jpg);
    background-size: cover;
    min-height: 600px;
}
.home .first div.col {
    max-width: 800px;
}
.av-card {
    opacity: 0.95;
    border-radius: 10px !important;
}
</style>
<script>
export default {
    name: "home",
    data() {
        return {
            selected: null,
            alert: false,
            items: [
                {
                    src: `${process.env.BASE_URL}lucidChartNodeReports-EO1mtN9VUAAKK8K.jpg`,
                    title: "Nodejs Diagnostic Reports",
                    subtitle: 'Node.js reports include JavaScript and native stack traces, heap statistics, platform information, resource usage, and more. Gather and analyze diagnostic reports in one streamlined interface.',
                    url: 'https://blog.brakecode.com/nodejs-diagnostic-reports/'
                },
                {
                    src: `https://res.cloudinary.com/june07/image/upload/v1616441102/brakecode/blog/discs-drive-image-suspend-cloud-service/Screenshot_2021-03-22_122433.png`,
                    title: 'DISCS',
                    subtitle: `Save $$$ on your cloud spend cost by seamlessly suspending and restoring instances to/from disk.`,
                    url: 'https://blog.brakecode.com/discs-drive-image-suspend-cloud-service/'
                },
                {
                    src: `${process.env.BASE_URL}brakecode-dashboard-1.png`,
                    title: 'PADS',
                    subtitle: `Enable secure remote debugging via fully managed certificate based SSH tunnels. V8 Inspector Protocol websockets are tunneled using secure encryption and API keys.`,
                    url: 'https://blog.brakecode.com/pads/'
                },
                {
                    src: `${process.env.BASE_URL}materialcssTransparentTooltips.gif`,
                    title: 'NiM Chromium Extension Integration',
                    subtitle: 'Plays nicely with Node.js --inspector Manager (NiM) by adding in client diagnostic reports and remote debugging capabilities.',
                    url: 'https://june07.com/nim'
                },
            ],
            active: undefined
        };
    },
    methods: {
        signup() {
            this.$auth.loginWithRedirect({
                appState: { targetUrl: "/dashboard" },
            });
        },
        expand(index) {
            this.selected = index;
        }
    },
    computed: {
        xLargeButton: function () {
            return this.$vuetify.breakpoint.xs ? false : true;
        },
    },
    components: {},
};
</script>
